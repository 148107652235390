import { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axios from "axios";
import { eventAPI } from "../../services/ApiServices";
//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EventBanner = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        message: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (!formData.mobile) {
            newErrors.mobile = 'Mobile number is required';
        }
        if (!formData.message) {
            newErrors.message = 'Message is required';
        }

        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true);

            axios.post(eventAPI, formData)
                .then((response) => {
                    setIsLoading(false);

                    // Reset form after submission
                    setFormData({
                        name: "",
                        email: "",
                        mobile: "",
                        message: ""
                    });

                    toast.success(response?.data?.message || 'Thankyou for contacting Us! ', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .catch((error) => {
                    console.error("Error submitting the form:", error);
                    toast.error(error.message || 'Something went wrong', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setIsLoading(false);
                });
        } else {
            setErrors(newErrors);
        }
    }

    return (
        <div className="events-banner">
            <div className="container">
                <div className="events-banner__inner">
                    <div className="events-banner__content">
                        <h1 className="main-title-50">Events Page Title</h1>
                        <p className="events-banner__paragraph">We are committed to being a business that balances purpose with profit that create value for all our stakeholders.</p>

                        <a className='btn-light' href="https://app.insightai.in/register" target="_blank" rel="noopener noreferrer" data-text="Register"><span className='btn-wrapper'>Register</span></a>

                    </div>
                    <div className="events-banner__form">
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="event-name">Name</Label>
                                <Input
                                    id="event-name"
                                    name="name"
                                    placeholder="Enter name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <span className="error">{errors.name}</span>}
                            </FormGroup>
                            <FormGroup>
                                <Label for="event-email">Email</Label>
                                <Input
                                    id="event-email"
                                    name="email"
                                    placeholder="Enter your email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <span className="error">{errors.email}</span>}

                            </FormGroup>
                            <FormGroup>
                                <Label for="event-number">Mobile No.</Label>
                                <Input
                                    id="event-number"
                                    name="mobile"
                                    placeholder="Enter your mobile number"
                                    type="tel"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />
                                {errors.mobile && <span className="error">{errors.mobile}</span>}

                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleText">Message</Label>
                                <Input
                                    id="exampleText"
                                    name="message"
                                    type="textarea"
                                    placeholder="Enter your message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                                {errors.message && <span className="error">{errors.message}</span>}

                            </FormGroup>

                            <button type="submit" className="btn dark-bg" data-text={isLoading ? " " : "Submit"}>{isLoading ?
                                <span className='small-loader'>
                                    <svg width="30" height="30" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#f6f6f6" data-testid="oval-svg">
                                        <g fill="none" fillRule="evenodd">
                                            <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                                <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#f6f6f68a" strokeWidth="3"></circle>
                                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                : <span className='btn-wrapper'>Submit</span>}</button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventBanner;