import axios from "axios";

//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const contactAPI = 'https://dev-app.insightai.in:8080/user/query/contact_us/';

export const ContactService =async (data) => {

    // Check if the payload is correct
    if (!data) {
        toast.error('Issue with data');
        return
    }
    try{
        const request = await axios.post(contactAPI, data);
        return request;
    }catch (err){
        throw err;
    }
}

//newsLetter

export const newsletterAPI = 'https://dev-app.insightai.in:8080/user/query/newsletter/';

//  const NewsLetterService = (data) => {
//     if (!data) {
//         toast.error('Issue with data');
//         return
//     }
//     const request = axios.post(newletterAPI, data);
//     request.then((res) => {
//         const successMessage = res.data.message;
//         toast.success(successMessage, {
//             position: "bottom-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//         });
//     }).catch(err => {
//         const errorMessage = err.response.data.email[0];

//         toast.error(errorMessage, {
//             position: "bottom-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "colored",
//             transition: Bounce,
//         });
//     });

// }

//blogs

export const blogsAPI = 'https://dev-app.insightai.in:8080/blog/list_post';

//News
export const newsAPI = 'https://dev-app.insightai.in:8080/blog/fetch_news_by_keyword/';

//Events
export const eventAPI = 'https://e738-103-191-182-163.ngrok-free.app/blog/create_event/';
