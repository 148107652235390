import { useState } from "react";

import InnerPageBanner from "../../components/Banner/InnerPageBanner";
import { Link, Element } from "react-scroll";


const CancellationAndRefund = () => {
    const [activeNavItem, setActiveNavItem] = useState(null);

    const handleActive = (to) => {
        setActiveNavItem(to);
    };

    // Define the data
    const titleText = 'Cancellation and Refund Policy';
    const spanText = 'Cancellation and Refund Policy';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <>
            <InnerPageBanner miniText="Last updated: Oct 30, 2024" title={titleText} spanText={spanText} spanIndex={spanIndex} content="InsightAI offers flexible subscription management with clear cancellation and refund policies for monthly and annual plans." />

            <section className="privacy-policy">
                <div className="container">
                    <div className="privacy-policy__navigation">
                        <Link to="overview" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'overview' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Overview</Link>
                        <Link to="cancellation-policy" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'cancellation-policy' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Cancellation Policy</Link>

                        <Link to="refund-policy" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'refund-policy' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Refund Policy</Link>

                        <Link to="no-refunds" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'no-refunds' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>No Refunds on Special Offers:</Link>

                        <Link to="refund-processing" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'refund-processing' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Refund Processing:</Link>
                        <Link to="contact" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'contact' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Contact Us</Link>
                    </div>
                    <div className="privacy-policy__wrapper">
                        <Element id="overview" className="privacy-policy__condition">
                            <h1 className="title">Overview</h1>
                            <p>At Pinnacle Technologies and Media Solutions Pvt Ltd, we are committed to providing the best possible experience with our product, InsightAI, an AI-powered early fraud detection and risk control solution specifically designed for BFSI and Fintech companies. Our payment structure is based on a subscription model, either monthly or annually.
                            </p>
                            <p>We understand that there may be occasions where you need to cancel your subscription or request a refund. This policy outlines the guidelines for cancellations and refunds.</p>
                        </Element>
                        <Element id="cancellation-policy" className="privacy-policy__condition">
                            <h2 className="title">Cancellation Policy</h2>
                            <h2 className="subtitle">Monthly Subscription</h2>
                            <h2 className="sub-subtitle">Cancellation Request</h2>
                            <p>If you are subscribed to our monthly plan and wish to cancel, you can do so at any time. However, cancellations will only take effect at the end of the current billing cycle. Once the current billing cycle ends, your access to InsightAI will be revoked, and no further charges will be made to your account.                            </p>

                            <h2 className="sub-subtitle">How to Cancel:
                            </h2>
                            <p>You can cancel your subscription by accessing your account on our platform or by contacting our support team at support@insightai.in. A confirmation of your cancellation will be provided via email.
                            </p>

                            <h2 className="subtitle">Annual Subscription
                            </h2>
                            <h2 className="sub-subtitle">Cancellation Request:</h2>
                            <p>For annual subscriptions, cancellation is permitted at any time. However, similar to the monthly plan, access to InsightAI will remain active until the end of the current billing cycle.
                            </p>
                            <h2 className="sub-subtitle">How to Cancel:
                            </h2>
                            <p>Cancellations for annual plans can be requested by reaching out to our support team at support@insightai.in. Upon cancellation, you will receive an email confirming your request.
                            </p>
                        </Element>
                        <Element id="refund-policy" className="privacy-policy__condition">
                            <h2 className="title">Refund Policy</h2>

                            <p>Due to the nature of our service, refunds are handled under the following conditions:</p>

                            <h2 className="subtitle">Monthly Subscription Refunds:</h2>

                            <p>We do not offer refunds for monthly subscription payments once they have been processed. Upon cancellation, your subscription will remain active until the end of the current billing cycle, after which no further payments will be charged.
                            </p>

                            <h2 className="subtitle">Annual Subscription Refunds:</h2>

                            <p>For annual subscriptions, if cancellation occurs within 30 days of the payment date, you may be eligible for a partial refund. The refund amount will be calculated on a pro-rata basis, deducting the value of services rendered during the active period.
                            </p>
                            <p>Refunds will not be available for cancellations after 30 days from the payment date.</p>

                        </Element>
                        <Element id="no-refunds" className="privacy-policy__condition">
                            <h2 className="title">No Refunds on Special Offers:</h2>
                            <p>Please note that no refunds will be issued for subscriptions purchased under special offers, promotions, or discounts.</p>

                        </Element>
                        <Element id="refund-processing" className="privacy-policy__condition">
                            <h2 className="title">Refund Processing:</h2>
                            <p>Refund requests must be submitted to billing@insightai.in. Refunds, if applicable, will be processed within 10-15 business days and credited back to the original payment method.
                            </p>
                        </Element>
                        <Element id="contact" className="privacy-policy__condition">
                            <h2 className="title">Contact Us</h2>
                            <p>For any questions regarding cancellations, refunds, or our policy in general, feel free to reach out to our support team at:
                            </p>
                            <ul>
                                <li>Email: <a href="mailto:support@insightai.in" target="_blank" rel="noreferrer noopener">support@insightai.in</a></li>
                                <li>Phone: <a href="tel:+91-7768944118" target="_blank" rel="noreferrer noopener">+91-7768944118</a></li>

                            </ul>
                            <p>We are here to ensure that you have a seamless experience with InsightAI.
                            </p>

                        </Element>

                    </div>
                </div>
            </section>

        </>
    )
}
export default CancellationAndRefund;