import { Link } from "react-router-dom";

const BlogPageCard = ({ data }) => {
    const maxWords = 5;
    const truncatedTitle = data.title.split(' ').slice(0, maxWords).join(' ') + (data.title.split(' ').length > maxWords ? '...' : '');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long', day: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <div className="blogs-card">
            <a href={data.link || ''} target="_blank" rel="noopener noreferrer" className="blogs-card__link">
                <div className="blogs-card__img">
                    <img src={data?.img} alt="/" />
                </div>
                <div className="blogs-card__content">
                    <span className="mini-text">{data?.category}</span>
                    <h2 className="subtitle">
                        {truncatedTitle || ''}
                    </h2>
                    <div className="date-nd-time">{formatDate(data?.date) || ''} | {data?.readingTime || ''}</div>
                    <div className="blogs-card__author-info">Written By: <span className="author-name">{data?.author}</span></div>
                </div>
            </a>
            {/* <a href={data.link} target="_blank" rel="noopener noreferrer" className="blogs-card__link">
            </a> */}

        </div>
    )
}

export default BlogPageCard;