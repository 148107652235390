import './style.scss';

import { Link, NavLink } from 'react-router-dom';


const VerticalNav = ({ isSidebarActive, onClose}) => {
    const handleClose =()=>{
        onClose();
    }
    return (
        <div className={isSidebarActive ? 'side-menu active' : 'side-menu'}>
            <Link to="/" className='link side-menu__item' onClick={handleClose}>Home</Link>
            <Link to="/about-us" className='link side-menu__item' onClick={handleClose}>About Us</Link>
            {/* <Link to="/solutions" className='link side-menu__item' onClick={handleClose}>Solutions</Link> */}
            {/* <Link to="/blogs" className='link side-menu__item' onClick={handleClose}>Blogs</Link> */}
            {/* <Link to="/pricing" className='link side-menu__item'>Pricing</Link> */}
            {/* <Link to="/ecommerce" className='link side-menu__item' onClick={handleClose}>Ecommerce</Link> */}
            <Link to="/faqs" className='link side-menu__item' onClick={handleClose}>FAQs</Link>
            {/* <Link to="/events" className='link side-menu__item' onClick={handleClose}>Events</Link> */}
            <Link to="/contact" className='link side-menu__item' onClick={handleClose}>Contact</Link>
        </div>
    )
}

export default VerticalNav;