import { useState } from "react";

import InnerPageBanner from "../../components/Banner/InnerPageBanner";
import { Link, Element } from "react-scroll";


const ShippingAndDeliveryPolicy = () => {
    const [activeNavItem, setActiveNavItem] = useState(null);

    const handleActive = (to) => {
        setActiveNavItem(to);
    };

    // Define the data
    const titleText = 'Shipping and Delivery Policy';
    const spanText = 'Shipping and Delivery Policy';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <>
            <InnerPageBanner miniText="Last updated: Oct 30, 2024" title={titleText} spanText={spanText} spanIndex={spanIndex} content="InsightAI offers instant digital access to AI-powered fraud detection for BFSI and Fintech companies." />

            <section className="privacy-policy">
                <div className="container">
                    <div className="privacy-policy__navigation">
                        <Link to="overview" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'overview' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Overview</Link>
                        <Link to="digital-delivery" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'digital-delivery' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Digital Delivery                        </Link>

                        <Link to="delivery-timeline" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'delivery-timeline' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Delivery Timeline:</Link>

                        <Link to="subscription-management" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'subscription-management' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Subscription Management:</Link>

                        <Link to="no-physical-goods" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'no-physical-goods' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>No Physical Goods</Link>
                        <Link to="delivery-confirmation" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'delivery-confirmation' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Delivery Confirmation:</Link>
                        <Link to="changes" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'changes' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Changes to the Shipping and Delivery Policy:</Link>
                        
                        <Link to="contact" spy={true} smooth={true} duration={500} offset={-100} onSetActive={handleActive} className={activeNavItem === 'contact' ? 'privacy-policy__nav active' : 'privacy-policy__nav'}>Contact Us</Link>
                    </div>
                    <div className="privacy-policy__wrapper">
                        <Element id="overview" className="privacy-policy__condition">
                            <h1 className="title">Overview</h1>
                            <p>At Pinnacle Technologies and Media Solutions Pvt Ltd, we provide our solution, InsightAI, exclusively through digital channels. Our product is an AI-powered early fraud detection and risk control solution tailored for BFSI and Fintech companies. All services and subscriptions are delivered online, and there is no requirement for physical shipping or delivery.
                            </p>
                            
                        </Element>
                        <Element id="digital-delivery" className="privacy-policy__condition">
                            <h2 className="title">Digital Delivery</h2>
                            <h2 className="subtitle">Access to Services:                            </h2>
                            <p>Upon successful subscription to any of our plans (monthly or annual), you will receive immediate access to InsightAI via our online platform. Once your payment is processed, an email containing your account details and access instructions will be sent to the email address provided during registration.
                            </p>
                        </Element>
                        <Element id="delivery-timeline" className="privacy-policy__condition">
                            <h2 className="title">Delivery Timeline:</h2>

                            <p>For monthly subscriptions, access is granted immediately upon payment confirmation.</p>
                            <p>For annual subscriptions, access is also granted immediately upon payment confirmation.</p>
                            <p>Since our product is entirely digital, there are no shipping times or delays associated with physical products. Access to InsightAI is provided as soon as the payment has been successfully processed.
                            </p>

                        </Element>
                        <Element id="subscription-management" className="privacy-policy__condition">
                            <h2 className="title">Subscription Management:</h2>
                            <p>Your subscription can be managed entirely through your account dashboard. You can upgrade, downgrade, or cancel your subscription at any time, and any changes will reflect immediately in your account.
                            </p>

                        </Element>
                        <Element id="no-physical-goods" className="privacy-policy__condition">
                            <h2 className="title">No Physical Goods</h2>
                            <h2 className="subtitle">No Physical Shipping:                            </h2>

                            <p>As a fully digital solution, we do not ship any physical goods. All interactions, product updates, and service provisions occur via our online platform or through email communication.
                            </p>
                        </Element>
                        <Element id="delivery-confirmation" className="privacy-policy__condition">
                            <h2 className="title">Delivery Confirmation:</h2>

                            <p>You will receive an email confirmation when your subscription to InsightAI has been successfully processed. This confirmation will include your login credentials and details on how to access the platform.
                            </p>
                        </Element>
                        <Element id="changes" className="privacy-policy__condition">
                            <h2 className="title">Changes to the Shipping and Delivery Policy:</h2>

                            <p>Pinnacle Technologies and Media Solutions Pvt Ltd reserves the right to modify this Shipping and Delivery Policy at any time. Any changes will be posted on this page with an updated effective date.
                            </p>
                        </Element>
                        <Element id="contact" className="privacy-policy__condition">
                            <h2 className="title">Contact Us</h2>
                            <p>If you encounter any issues accessing your InsightAI account or services, please contact our support team. We are available to assist you in resolving any access or delivery-related concerns.
                            </p>
                            <ul>
                                <li>Email: <a href="mailto:support@insightai.in" target="_blank" rel="noreferrer noopener">support@insightai.in</a></li>
                                <li>Phone: <a href="tel:+91-7768944118" target="_blank" rel="noreferrer noopener">+91-7768944118</a></li>

                            </ul>
                            <p>We are here to ensure that you have a seamless experience with InsightAI.
                            </p>

                        </Element>

                    </div>
                </div>
            </section>

        </>
    )
}
export default ShippingAndDeliveryPolicy;