import CenterContent from "../../components/CenterContent";
import ImageTextCol from "../../components/SolutionsBlock/ImageTextCol";
import AnimatedImageText from "../../components/AnimatedImageText";
import ImageTextBanner from "../../components/Banner/imageTextBanner";
import Feature from "../../components/FeatureSections/Feature";
import Video from "../../components/HowItWorks/video";
// Feature Image
import reconcileImg from '../../assets/banner/innerpage.png';

const Ecommerce = () => {

    const bannerData = {
        titleText: 'Transform Shopping with Smart Product Recommendations',
        content: 'Enhance your retail experience with AI-driven, personalized solutions',
        image: reconcileImg
    };
    const centerContentData = {
        titleText: 'Experience the Future of Shopping with InsightAI',
        spanText: 'Future of Shopping',
        content: 'Enhance your retail experience with AI-driven, personalized solutions',
        spanIndex: 'Experience the Future of Shopping with InsightAI'.indexOf('Future of Shopping'),
        btnText: 'Book a Demo'
    };
    const data = {
        id: 'financial-statement-analysis',
        title: 'Advanced Data Reconciliation',
        subtitle: 'Financial Statement Analysis',
        content: '',
        list: ['Seamless Integration: Easily connect with major bookkeeping solutions like Tally and Zoho for effortless data syncing.', 'Instant Invoice Analysis: Quickly analyze bulk invoices to extract user data and insights.', 'Precise Ledger Analytics: Obtain detailed analytics for ledger data and comprehensive data reconciliation.', 'Automated Reporting: Generate accurate, professional reports for customers with a click.'],
        img: `${reconcileImg}`,
        reverse: false
    }

    return (
        <>
            <ImageTextBanner
                title={bannerData.titleText}
                subtitle={bannerData.content}
                image={bannerData.image}
            />
            <Video />
            {/* <section className="solutions-block">
                <ImageTextCol data={data} />
            </section> */}
            {/* <AnimatedImageText /> */}
            <Feature />

            <div className="getstarted-block">
                <div className="container">
                    <CenterContent
                        miniText="Get Started"
                        title={centerContentData.titleText}
                        spanText={centerContentData.spanText}
                        spanIndex={centerContentData.spanIndex}
                        content={centerContentData.content}
                        btnText={centerContentData.btnText} />
                </div>
            </div>
        </>
    )
}

export default Ecommerce;