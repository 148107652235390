import InnerPageBanner from "../../components/Banner/InnerPageBanner";
import PricingCardGrid from "./PricingCardGrid";
import ComparisonTable from "./comparisonTable";
import PricingFAQ from "./pricingFAQ";
import CenterContent from "../../components/CenterContent";

import { dummyPlans, dummyYearlyPlans } from "../../Utility/planDB";

const Pricing = () => {
    // Define the data
    const titleText = 'Fair pricing for any business';
    const spanText = 'Fair pricing';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);

    return (
        <>
            <InnerPageBanner miniText="" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Whether you're an individual, a small team, or a growing enterprise, we have a plan that aligns perfectly with your goals." />
            <PricingCardGrid monthlyPlan={dummyPlans} yearlyPlan={dummyYearlyPlans}/>
            {/* <ComparisonTable /> */}
            {/* <PricingFAQ /> */}
            <div className="getstarted-block">
                <div className="container">
                    <CenterContent miniText="Get Started" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Optimize all your images effortlessly right from your admin dashboard and experience the power of speed." btnText="Get started - It's free" />
                </div>
            </div>
        </>
    )
}

export default Pricing;