import CenterContent from "../../components/CenterContent";
import FaqAccordion from "../../components/FAQ/faqAccordions";

const PricingFAQ = () => {
    // Define the data
    const titleText = "All the answers you're looking for";
    const spanText = 'All the answers ';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <section className="pricing-faq">
            <div className="container">
                <CenterContent miniText="FREQUENTLY ASKED QUESTIONS" title={titleText} spanText={spanText} spanIndex={spanIndex} content="If you're new or looking for answers to your questions, this guide will help you learn more about our services and their features." />

                <div className="pricing-faq__grid">
                    <FaqAccordion />
                    <FaqAccordion />
                </div>
            </div>
        </section>
    )
}

export default PricingFAQ;