import { useState } from 'react';


const ImageTextBanner = ({ title, subtitle, image }) => {
    const [showVideoModal, setShowVideoModal] = useState(false);

    const toggleVideoModal = () => {
        setShowVideoModal(prev => !prev);
    }
    return (
        <section className="image-banner">
            {/* Background Pattern */}
            {/* <div className="background-pattern">
                <span className="bg-solid"></span>
                <span className="bg-pattern-wrapper">
                    <img src={pattern} alt="" />
                </span>
            </div> */}

            <div className="container">
                <div className="image-banner__content-box">
                    <h1 className="main-title">
                        {title}
                    </h1>

                    <div className="image-banner__description">
                        <p className="large-paragraph">
                            {subtitle}
                        </p>
                        <div className='image-banner__btn-wrapper'>
                            <a className='btn' href="https://calendly.com/insightai-sales/get-insightai" target="_blank" rel="noopener noreferrer" data-text="Book a Demo"><span className='btn-wrapper'>Book a Demo</span></a>

                        </div>

                    </div>
                </div>
                <div className="home-banner__img-box">
                    <img src={image} alt="Explore the dynamic Insight AI dashboard - a visual journey through cutting-edge APIs poised to elevate your projects to new heights." />
                </div>
            </div>

        </section>
    )
}

export default ImageTextBanner;