import { Link } from "react-router-dom";
import { Linkedin, Facebook, Instagram, Youtube, X } from "react-feather";

//img
import logo from './img/logo.svg';
import Twitter from './img/twitter-x (1).svg';
import Newsletter from "../NewsLetter";


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__nav">
                <div className="container">
                    <div className="footer__nav__left">
                        <Link to="/terms-nd-conditions" className="link light footer__nav__link">Terms & Conditions</Link>
                        

                        {/* <Link to="/faqs" className="link light footer__nav__link">Help</Link> */}
                        {/* <Link to="/contact" className="link light footer__nav__link">Contact</Link> */}
                    </div>
                    <div className="footer__nav__right social-media">
                        <a href="https://www.linkedin.com/company/insightai-technologies/" target="_blank" rel="noopener noreferrer" className="social-media__link"><Linkedin /></a>
                        <a href="https://www.facebook.com/profile.php/?id=61552129252904" target="_blank" rel="noopener noreferrer" className="social-media__link"><Facebook /></a>
                        <a href="https://twitter.com/getInsightAI" target="_blank" rel="noopener noreferrer" className="social-media__link"><img src={Twitter} alt="twitter" /></a>
                        <a href="https://www.instagram.com/insightai.in/" target="_blank" rel="noopener noreferrer" className="social-media__link"><Instagram /></a>
                        <a href="https://www.youtube.com/@TeamInsightAI" target="_blank" rel="noopener noreferrer" className="social-media__link"> <Youtube /></a>

                    </div>
                </div>
            </div>
            <div className="footer__wrapper">
                <div className="container">
                    <div className="footer__main">
                        <img src={logo} alt="InsightAI Logo" />
                        <p className="footer__content">
                            Precision Analytics for the Modern Enterprise
                        </p>
                    </div>
                    <div className="footer__row">
                        <div className="footer__row__column footer__feature">
                            <h3 className="sub-subtitle">
                                Policies
                            </h3>
                            <ul>
                                
                                <li><Link to='/privacy-policy' className="footer-link link light"><span>Privacy Policy</span></Link></li>
                                <li><Link to='/cancellation-and-refund-policy' className="footer-link link light"><span>Cancellation and Refund Policy</span></Link></li>
                                {/* <li><Link to='/shipping-and-delivery-policy' className="footer-link link light"><span>Shipping and Delivery Policy</span></Link></li> */}
                                <li><Link to='/terms-nd-conditions' className="footer-link link light"><span>Terms & Conditions</span></Link></li>

                            </ul>
                        </div>
                        {/* <div className="footer__row__column footer__resources">
                            <h3 className="sub-subtitle">
                                Resources
                            </h3>
                            <ul>
                                <li><Link to='/' className="footer-link link light"><span>Support center</span></Link></li>
                                <li> <Link to='/' className="footer-link link light"><span>Documentation</span></Link></li>
                                <li> <Link to='/' className="footer-link link light"><span>Community</span></Link></li>
                                <li><Link to='/' className="footer-link link light"><span>Hosting</span></Link></li>
                            </ul>
                        </div> */}
                        <div className="footer__row__column footer__company">
                            <h3 className="sub-subtitle">Company
                            </h3>
                            <ul>
                                <li><Link to='/about-us' className="footer-link link light"><span>About us</span></Link></li>
                                {/* <li><Link to='/pricing' className="footer-link link light"><span>Pricing</span></Link></li> */}
                                {/* <li><Link to='/solutions' className="footer-link link light"><span>Solutions</span></Link></li> */}
                                <li><Link to='/contact' className="footer-link link light"><span>Contact us</span></Link></li>
                                <li><Link to='/blogs' className="footer-link link light"><span>Blogs</span></Link></li>
                            </ul>
                        </div>
                        <div className="footer__row__column footer__newsletter">
                            <h3 className="sub-subtitle">Join our Newsletter for exciting updates
                            </h3>
                            <Newsletter />
                        </div>
                    </div>


                </div>
            </div>
            <div className="footer__copyright">
                <div className="container">
                    <span className="large-paragraph">Pinnacle Technologies and Media Solution Pvt Ltd copyright reserved 2024</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;