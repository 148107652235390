import { useState } from "react"
import { Card, CardBody } from "reactstrap"

const PricingCard = ({ setShowContactModal, plan, setDetails }) => {
    const [showAll, setShowAll] = useState(false);

    const toggleModal = () => {
        setShowContactModal(prev => !prev);
        setDetails(plan);
    }

    // Check if there are more than 5 items
    const displayItems = showAll ? plan.features : plan.features.slice(0, 5);

    return (
        <Card className="pricing-card">
            <CardBody >
                <div>

                    <h2 className="sub-subtitle">{plan?.planName || ''}</h2>
                    <h2 className="title">{plan?.price || ''}/{plan?.duration || ''}</h2>


                    <ul className="pricing-card__list">
                        {
                            plan?.features &&

                            displayItems.map((item, index) => (
                                <li key={index}>{item.product || ''}</li>
                            ))

                        }

                        {plan.features.length > 5 && (
                            <button className='readBtn' onClick={() => setShowAll(!showAll)}>
                                {showAll ? 'Show Less' : 'Read More'}
                            </button>
                        )}

                    </ul>
                    {/* <div className="pricing-card__amount">
                    <sup>$</sup>
                    <span className="value">19</span>
                    <span className="duration">/month</span>
                </div> */}
                </div>


                <button className="btn full dark-bg" data-text="Buy Now" onClick={toggleModal}><span className="btn-wrapper">Buy Now</span></button>
            </CardBody>
        </Card>
    )
}

export default PricingCard;