// src/Pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div style={{ textAlign: 'center', marginBlock: '100px' }}>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
    <Link className="btn" to="/" style={{marginTop:'30px'}}>Go back to Home</Link>
  </div>
);

export default NotFound;
