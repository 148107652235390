import { useState } from "react";
import ContactModal from "../../components/Modals/ContactModal";
import PricingCard from "../../components/PricingCard";


const PricingCardGrid = ({ monthlyPlan, yearlyPlan }) => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [details, setDetails] = useState(null);
    return (
        <div className="pricing-grid">
            <div className="container">
                <div className="pricing-grid__wrapper">
                    {
                        monthlyPlan.map((plan) => (
                            <PricingCard plan={plan} setShowContactModal={setShowContactModal} setDetails={setDetails}/>
                        ))
                    }

                    {/* <PricingCard setShowContactModal={setShowContactModal} />
                    <PricingCard setShowContactModal={setShowContactModal} /> */}
                </div>
            </div>
            <ContactModal details={details} showContactModal={showContactModal} toggleContactModal={() => setShowContactModal(!showContactModal)} />
        </div>
    )
}

export default PricingCardGrid;