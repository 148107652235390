import { useState } from 'react';
import { Form, FormGroup, Input, Modal, ModalBody } from 'reactstrap';
import { ContactService } from "../../services/ApiServices";

import { X } from 'react-feather';

//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactModal = ({ showContactModal, toggleContactModal, details }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    //handling Input Data
    const handleValue = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Basic validation
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (!formData.phone) {
            newErrors.phone = 'Phone number is required';
        }
        if (!formData.message) {
            newErrors.message = 'Message is required';
        }

        if (Object.keys(newErrors).length === 0) {
            try {
                setIsLoading(true);
                await ContactService(formData);
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });

                toast.success('Thankyou for contacting Us! ', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            } catch (err) {
                toast.error(err.message || 'Something went wrong', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } finally {
                setIsLoading(false);
            }

        } else {
            setErrors(newErrors);
        }
    }

    return (
        <div id='contactModel'>
            <Modal isOpen={showContactModal} toggle={toggleContactModal} className="contact-modal">
                <button className='close-btn' onClick={toggleContactModal}><X /></button>
                <ModalBody>
                    <div className='contact-modal__header'>
                        {/* <h2 className='title'>We are delighted to meet you!</h2> */}
                        <h2 className='title'>Checkout!</h2>

                    </div>
                    <Form>
                        <FormGroup>
                            <Input
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleValue}
                                required
                            />
                            {errors.name ? <span className="error">{errors.name}</span> : ''}
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="email"
                                name="email"
                                placeholder="Email address"
                                type="email"
                                value={formData.email}
                                onChange={handleValue}
                                required
                            />
                            {errors.email ? <span className="error">{errors.email}</span> : ''}
                        </FormGroup>

                        <FormGroup>
                            <Input
                                id="phone"
                                name="phone"
                                placeholder="Phone number"
                                type="tel"
                                value={formData.phone}
                                onChange={handleValue}
                                required
                            />
                            {errors.phone ? <span className="error">{errors.phone}</span> : ''}
                        </FormGroup>
                        {/* <FormGroup className='message-group'>
                            <Input
                                id="user-message"
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleValue}
                                type="textarea"
                                required
                            />
                            {errors.message ? <span className="error">{errors.message}</span> : ''}
                        </FormGroup> */}
                        <button type="submit" className="btn full dark-bg" data-text={isLoading ? " " : `Pay ${details?.price || ''}`} onClick={handleSubmit}>
                            {isLoading ?
                                <span className='small-loader'>
                                    <svg width="30" height="30" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#f6f6f6" data-testid="oval-svg">
                                        <g fill="none" fillRule="evenodd">
                                            <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                                <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#f6f6f68a" strokeWidth="3"></circle>
                                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                : <span className='btn-wrapper'>Pay {details?.price || ''}</span>}
                        </button>
                    </Form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ContactModal; 