import Image1 from './img/image-1.png';
import Image2 from './img/image-2.png';
import Image3 from './img/image-3.png';
import Image4 from './img/image-4.png';
import Image5 from './img/image-5.png';
import Image6 from './img/image-6.png';
import Image7 from './img/optimizing.png';
import Image8 from './img/Understand.png';
import Image9 from './img/Large.png';



export const blogData = [
    {
        img: `${Image1}`,
        date: '01, March 2024',
        readingTime: '3 min',
        title: "Introducing InsightAI: Revolutionising Financial Crime Prevention",
        author: 'Akash Chandra',
        link: 'https://www.linkedin.com/pulse/introducing-insightai-revolutionising-financial-okrmc/?trackingId=PvGTa1OHTQ2x50CwUZ3cnA%3D%3D',
        category: 'AML'
    },
    {
        img: `${Image7}`,
        date: 'May 21, 2024',
        readingTime: '4 min',
        title: "Optimizing LLM Training: Memory Management and Multi-GPU Techniques",
        author: 'Akash Chandra',
        link: 'https://www.linkedin.com/pulse/optimizing-llm-training-memory-management-multi-gpu-akash-chandra-wxn6c/?trackingId=Qyxu5Ay%2FSgympExTz8pKiw%3D%3D',
        category: 'ML'
    },
    {
        img: `${Image8}`,
        date: 'May 10, 2024',
        readingTime: '5 min',
        title: "Understanding Image Generation: The Role of Diffusion and Segmentation Models",
        author: 'Akash Chandra',
        link: 'https://www.linkedin.com/pulse/understanding-image-generation-role-diffusion-models-akash-chandra-4fmyc/?trackingId=Qyxu5Ay%2FSgympExTz8pKiw%3D%3D',
        category: 'AI'
    },
    {
        img: `${Image9}`,
        date: 'May 1, 2024',
        readingTime: '4 min',
        title: "Large Language Models Unveiled: A Practical Approach to Advanced Text Generation",
        author: 'Akash Chandra',
        link: 'https://www.linkedin.com/pulse/large-language-models-unveiled-practical-approach-advanced-chandra-nz9jc/?trackingId=Qyxu5Ay%2FSgympExTz8pKiw%3D%3D',
        category: 'ML'
    },
    
    {
        img: `${Image2}`,
        date: '12, March 2024',
        readingTime: '3 min',
        title: "Harnessing AI for Financial Crime Prevention: A Paradigm Shift in Safeguarding Economies",
        author: 'Vivek Kumar',
        link: 'https://www.linkedin.com/pulse/harnessing-ai-financial-crime-prevention-paradigm-hpbxc/',
        category: 'AI'
    },
    {
        img: `${Image3}`,
        date: '18, March 2024',
        readingTime: '3 min',
        title: "The Evolving Landscape of Financial Crime in the Digital Era",
        author: 'Shesha Sahu',
        link: 'https://www.linkedin.com/pulse/evolving-landscape-financial-crime-digital-era-fkoqc/?trackingId=wDYn38XTRea1hukfiHtyHw%3D%3D',
        category: 'Financial Crime'
    },
    {
        img: `${Image4}`,
        date: '20, March 2024',
        readingTime: '3 min',
        title: "The Power of AI and Machine Learning in Case Investigations",
        author: 'Akash Chandra',
        link: 'https://www.linkedin.com/pulse/power-ai-machine-learning-case-investigations-4f4ke/?trackingId=jbBmMR4j8ajCSzD2llREVw%3D%3D',
        category: 'ML'
    },
    {
        img: `${Image5}`,
        date: '20, March 2024',
        readingTime: '4 min',
        title: "Enhanced Financial Crime Detection Using Graph Analytics",
        author: 'Shivani Pande',
        link: 'https://www.linkedin.com/pulse/enhanced-financial-crime-detection-using-graph-joxbc/?trackingId=QZU82EF%2FMx9NOTIoSN2T4Q%3D%3D',
        category: 'Graph Analytics'
    },
    {
        img: `${Image6}`,
        date: '21, March 2024',
        readingTime: '4 min',
        title: "Leveraging Big Data for Enhanced Financial Security",
        author: 'Akash Chandra',
        link: 'https://www.linkedin.com/pulse/leveraging-big-data-enhanced-financial-security-qsuvc/',
        category: 'Big Data'
    },
]

