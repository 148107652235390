import { lazy, Suspense } from "react";

// Lazy load components
const Banner = lazy(() => import("../../components/Banner"));
const PlainBanner = lazy(() => import("../../components/Banner/PlainBanner"));
const BlogSlider = lazy(() => import("../../components/BlogSlider"));
const Customers = lazy(() => import("../../components/Customers"));
const FAQ = lazy(() => import("../../components/FAQ"));
const HowItWork = lazy(() => import("../../components/HowItWorks"));
const Integration = lazy(() => import("../../components/Integration"));
const News = lazy(() => import("../../components/News"));
const OurAI = lazy(() => import("../../components/OurAI"));
const StickyBlock = lazy(() => import("../../components/StickyBlock"));
const Testimonial = lazy(() => import("../../components/Testimonials"));
const Visuals = lazy(() => import("../../components/Visuals"));
const Databases = lazy(() => import("../../components/Databases"));
const Recognitions = lazy(() => import("../../components/Recognitions"));
const ClientCrousel = lazy(()=> import("../../components/clientCrousel/index"));

const Home = () => {
  return (
    <>
      <Suspense fallback={<> </>}>
        <Banner />
      </Suspense>
      <Suspense fallback={<> </>}>
        {/* <ClientCrousel /> */}
      </Suspense>
      {/* <Suspense fallback={<> </>}>
        <PlainBanner />
      </Suspense> */}
      <Suspense fallback={<> </>}>
        {/* <Recognitions /> */}
      </Suspense>

      <Suspense fallback={<> </>}>
        {/* <Databases /> */}
      </Suspense>

      <Suspense fallback={<> </>}>
        <Customers />
      </Suspense>

      <Suspense fallback={<> </>}>
        <HowItWork />
      </Suspense>

      <Suspense fallback={<> </>}>
        <StickyBlock reverseClass="reverse" />
      </Suspense>

      {/* <Suspense fallback={<> </>}>
        <Visuals />
      </Suspense> */}

      <Suspense fallback={<> </>}>
        <Integration />
      </Suspense>

      <Suspense fallback={<> </>}>
        <OurAI />
      </Suspense>

      <Suspense fallback={<> </>}>
        <BlogSlider />
      </Suspense>

      {/* Lazy load Testimonial if needed */}
      {/* <Suspense fallback={<> </>}>
        <Testimonial />
      </Suspense> */}

      {/* Lazy load News if needed */}
      {/* <Suspense fallback={<> </>}>
        <News />
      </Suspense> */}
    </>
  )
}

export default Home;