import ProfessionalJourney from "../../components/ProfessionalJourney";
import EventBanner from "./eventBanner";
import EventCrousel from "./eventsCrousel";
import PastEvents from "./pastEvents";

const Events = () => {

    return (
        <>
            <EventBanner />
            <ProfessionalJourney />
            <EventCrousel />
            <PastEvents />
        </>
    )
}

export default Events;