export const dummyPlans = [
    {
        planName: "Silver Plan",
        price: "₹20,000",
        duration: "month",
        credits: "10,000",
        active:true,
        features: [
            { product: "WizDoc" },
            { product: "WizDB" },
            { product: "AnalyticsDB" },
            { product: "Cashflow Forecasting" },
            { product: " ChatBot" },
        ],
    },
    {
        planName: "Gold Plan",
        price: "₹50,000",
        duration: "month",
        credits: "10,000",
        active:false,
        features: [
            { product: "WizDoc" },
            { product: "WizDB" },
            { product: "AnalyticsDB" },
            { product: "Cashflow Forecasting" },
            { product: " ChatBot" },
            { product: "Bank Statement Analysis" },
            { product: "AI underwriting" },
            { product: "API integration" },
        ],
    },
    {
        planName: "(Platinum) Enterprise plan",
        price: "Custom",
        duration: "month",
        credits: "Custom Credit (Pay as you go)",
        active:false,
        features: [
            { product: "WizDoc" },
            { product: "WizDB" },
            { product: "AnalyticsDB" },
            { product: "Cashflow Forecasting" },
            { product: " ChatBot" },
            { product: "Bank Statement Analysis" },
            { product: "AI underwriting" },
            { product: "API integration" },
            { product: "Custom Deployment", },
            { product: "Fraud Detection API", },
            { product: "Risk mitigation API", },

        ],
    }
];

export const dummyYearlyPlans = [
    {
        planName: "Silver Plan",
        price: "₹1,92,000",
        duration: "Year",
        credits: "1,20,000",
        active:true,
        features: [
            { product: "WizDoc" },
            { product: "WizDB" },
            { product: "AnalyticsDB" },
            { product: "Cashflow Forecasting" },
            { product: " ChatBot" },
        ],
    },
    {
        planName: "Gold Plan",
        price: "₹4,80,000",
        duration: "Year",
        credits: "1,20,000",
        active:false,
        features: [
            { product: "WizDoc" },
            { product: "WizDB" },
            { product: "AnalyticsDB" },
            { product: "Cashflow Forecasting" },
            { product: " ChatBot" },
            { product: "Bank Statement Analysis" },
            { product: "AI underwriting" },
            { product: "API integration" },
        ],
    },
    {
        planName: "(Platinum) Enterprise plan",
        price: "Custom",
        duration: "Year",
        credits: "Custom Credit (Pay as you go)",
        active:false,
        features: [
            { product: "WizDoc" },
            { product: "WizDB" },
            { product: "AnalyticsDB" },
            { product: "Cashflow Forecasting" },
            { product: " ChatBot" },
            { product: "Bank Statement Analysis" },
            { product: "AI underwriting" },
            { product: "API integration" },
            { product: "Custom Deployment", },
            { product: "Fraud Detection API", },
            { product: "Risk mitigation API", },

        ],
    }
];
