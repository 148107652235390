
const CenterContent = ({ miniText, title, spanText, spanIndex, content, btnText }) => {

    // Split the text into two parts: before and after the span
    const textBeforeSpan = title.slice(0, spanIndex);
    const textAfterSpan = title.slice(spanIndex + spanText.length);

    return (
        <div className="center-content">
            {miniText && <span className="mini-text">{miniText}</span>}
            <h2 className="title">{textBeforeSpan} <span>{spanText || ''}</span> {textAfterSpan}</h2>
            <p className="large-paragraph">{content || ''}</p>

            {
                btnText && 
                <a className='btn dark-bg' href="https://app.insightai.in/" target="_blank" rel="noopener noreferrer" data-text={btnText}><span className='btn-wrapper'>{btnText || ''}</span></a>
            }
        </div>
    )
}

export default CenterContent;