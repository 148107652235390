import { Flag, MessageSquare, Star } from "react-feather";

const JourneyCard = ({reverseClass}) => {
    return (
        <div className={reverseClass ? "journey-card reverse" : "journey-card"}>
            <div className="journey-card__info">
                <div className="journey-card__info__wrapper">
                    <span className="journey-card__icon"><Flag /></span>
                    <span className="journey-card__subtitle">Hello World!</span>
                    <h2 className="journey-card__title">Company Inception</h2>
                    <p>The company is founded by a team of passionate entrepreneurs. The vision, mission, and values are defined.</p>
                </div>

            </div>
            <div className="journey-card__time">
                <span className="date">January 2020</span>
                <span className="journey-count">Milestone 1</span>
            </div>
        </div>
    )
}
export default JourneyCard;