import React, { useState, useEffect } from "react";
import InnerPageBanner from "../../components/Banner/InnerPageBanner";
import BlogPageCard from "../../components/BlogPageCards";
import { InputGroup, Input, Button } from "reactstrap";
import { Search } from "react-feather";
import RecentPosts from "./RecentPosts";
import Pagination from "../../components/Pagination";
import axios from "axios";
import { blogData } from "./data";

const BlogPage = () => {
    // const [blogData, setBlogData] = useState(blogData);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(blogData);
    const [showLoader, setShowLoader] = useState(false);
    const [error, setError] = useState(false);
    const itemsPerPage = 3; // Number of items per page

    // useEffect(() => {
    //     setShowLoader(true)
    //     axios.get('https://dev-app.insightai.in:8080/blog/list_post')
    //         .then(res => {
    //             setShowLoader(false);
    //             if (res && res.status == 200) {
    //                 setBlogData(res.data);
    //                 setFilteredData(res.data.slice(0, itemsPerPage)); // Set initial filtered data
    //             }
    //             else{
    //                 setError(true);
    //             }
    //         })
    //         .catch(err => {
    //             setShowLoader(false);
    //             setError(true);
    //         });
    // }, []);

    const handlePageClick = (selectedItem) => {
        const offset = selectedItem * itemsPerPage;
        setFilteredData(blogData.slice(offset, offset + itemsPerPage));
        setCurrentPage(selectedItem);
    };

    // const handleSearch = (query) => {
    //     setSearchQuery(query);
    //     let filteredResults = blogData.filter(item => {
    //         const titleMatch = item.title.toLowerCase().includes(query.toLowerCase());
    //         const authorMatch = item.author.toLowerCase().includes(query.toLowerCase());
    //         const categoryMatch = item.category.toLowerCase().includes(query.toLowerCase());
    //         return titleMatch || authorMatch || categoryMatch;
    //     });
    //     setFilteredData(filteredResults.slice(0, itemsPerPage)); // Update filtered data with search results
    // };

    const fliterByCategory = (category) => {
        let filtered = blogData;
        filtered = blogData.filter(blog => blog.category === category);
        setFilteredData(filtered);
    }

    return (
        <>
            <InnerPageBanner miniText="" title="" spanText="Our Blogs" content="Optimize all your images effortlessly right from your admin dashboard and experience the power of speed." />

            <div className="blog-page-body">
                <div className="container">
                    <div className="blogs-categories">
                        <button onClick={() => fliterByCategory("AML")} className="category-link">AML</button>
                        <button onClick={() => fliterByCategory("ML")} className="category-link">ML</button>
                        {/* <button onClick={() => fliterByCategory("Risk Resiliency")} className="category-link">Risk Resiliency</button> */}
                        <button onClick={() => fliterByCategory("AI")} className="category-link">AI</button>
                        {/* <button onClick={() => fliterByCategory("KYC")} className="category-link">KYC</button> */}
                        {/* <button onClick={() => fliterByCategory("Entity Resolution")} className="category-link">Entity Resolution</button> */}
                        {/* <button onClick={() => fliterByCategory("Data Quality")} className="category-link">Data Quality</button> */}
                        <button onClick={() => fliterByCategory("Financial Crime")} className="category-link">Financial Crime</button>
                        <button onClick={() => fliterByCategory("Decision Intelligence")} className="category-link">Decision Intelligence</button>
                        <button onClick={() => fliterByCategory("Big Data")} className="category-link">Big Data</button>
                        <button onClick={() => fliterByCategory("Graph Analytics")} className="category-link">Graph Analytics</button>
                    </div>
                    <div className="blog-page-body__wrapper">
                        <div className="blogs-column">
                            {/* <div className="search-box">
                                <h2 className="sub-subtitle">Search</h2>
                                <InputGroup>
                                    <Input placeholder="Search..." type="search" value={searchQuery} onChange={(e) => handleSearch(e.target.value)} />
                                    <Button>
                                        <Search />
                                    </Button>
                                </InputGroup>
                            </div> */}
                            <RecentPosts data={blogData || []} />
                        </div>
                        <div className="blog-page-body__main-content">
                            {showLoader ?
                                <div className='loader-wrapper'>
                                    <svg width="100" height="100" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#926BFF" data-testid="oval-svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <g transform="translate(1 1)" stroke-width="2" data-testid="oval-secondary-group">
                                                <circle stroke-opacity=".5" cx="0" cy="0" r="20" stroke="#926bffa1" stroke-width="2"></circle>
                                                <path d="M20 0c0-9.94-8.06-20-20-20">
                                                    <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                : (error || !filteredData.length) ?
                                    <h2 className="error-message">
                                        No Data Found...
                                    </h2>
                                    :
                                    <>
                                        {filteredData.map((item) => (
                                            <BlogPageCard key={item.id} data={item} />
                                        ))}
                                        {filteredData.length >= itemsPerPage && blogData.length > itemsPerPage &&
                                            <Pagination
                                                data={blogData || []}
                                                itemsPerPage={itemsPerPage}
                                                onPageChange={handlePageClick}
                                                forcePage={currentPage}
                                            />
                                        }

                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogPage;
