import { lazy, Suspense } from "react";


// Lazy load components
const InnerPageBanner = lazy(() => import("../../components/Banner/InnerPageBanner"));
const ProfessionalJourney = lazy(() => import("../../components/ProfessionalJourney"));
const Teams = lazy(() => import("../../components/Teams"));
const VideoBlock = lazy(() => import("../../components/VideoBlock"));

const AboutUs = () => {
    // Define the data
    const titleText = 'The Team Crafting Our Success';
    const spanText = 'Team';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <>
            {/* Lazy load InnerPageBanner */}
            <Suspense fallback={<></>}>
                <InnerPageBanner miniText="About Us" title={titleText} spanText={spanText} spanIndex={spanIndex} content="Our expert team combines AI, data analytics, and fintech skills to create innovative solutions that revolutionize the financial industry." />
            </Suspense>
            {/* Lazy load VideoBlock */}
            <Suspense fallback={<></>}>
                {/* <VideoBlock /> */}
            </Suspense>

             {/* Commented out but can be lazy-loaded */}
            {/* <Suspense fallback={<></>}>
                <ProfessionalJourney />
            </Suspense> */}

            {/* Lazy load Teams */}
            <Suspense fallback={<></>}>
                <Teams />
            </Suspense>
        </>
    )
}

export default AboutUs;