import CenterContent from "../CenterContent";
import JourneyCard from "./journeyCard";

const ProfessionalJourney = () => {
    // Define the data
    const titleText = 'Company achievements timeline';
    const spanText = 'achievements';
    // Calculate the index where the span should be inserted
    const spanIndex = titleText.indexOf(spanText);
    return (
        <section className="professional-journey">
            <div className="container">
                <CenterContent miniText="Professional journey" title={titleText} spanText={spanText} spanIndex={spanIndex} content="We are committed to being a business that balances purpose with profit that create value for all our stakeholders." />

                <div className="timeline">
                    <JourneyCard />
                    <JourneyCard reverseClass="reverse"/>
                    <JourneyCard />

                </div>
            </div>
        </section>
    )
}

export default ProfessionalJourney;